<template>
  <Nuxt id="main" />
</template>

<script>
export default {
  name: 'LayoutHome',
  mounted() {
    this.checkPreview()
  },
  methods: {
    checkPreview() {
      const query = this.$route.query
      if (query['sanity-preview-perspective']) {
        services.sanityService.activatePreview(
          query['sanity-preview-perspective'],
          query['sanity-preview-secret']
        )
        this.$nuxt.refresh()
      }
    },
  },
}
</script>
